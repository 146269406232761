.root {
  background-color: white;
  color: black;
  border-radius: 3px;
  height: 40px !important;
  padding-left: 5px;
  padding-right: 5px;
  width: 80px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.negative {
  font-size: 2em;
}

.count {
  font-size: 2em;
}

.positive {
  font-size: 2em;
}
