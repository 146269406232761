.root {
  display: flex;
  flex-direction: row;
  margin: 5px;
  align-items: center;
  flex: 1 0;
}

.countdown {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--main-tint-color);
  text-decoration: none;
  flex-direction: column;
  font-weight: bold;
  font-size: 0.7em;
}

.logo img {
  margin: 5px;
  height: 45px;
}

@media only screen and (max-width: 970px) {
  .logo {
    justify-content: center;
  }

  .logo img {
    margin: 0;
    height: 40px;
  }
}

@media only screen and (min-width: 970px) {
  .logo {
    width: 450px !important;
    margin-right: 70px;
  }
}
