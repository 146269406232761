:root {
  --main-tint-color: #E94142;
  --main-gray-color: #8a8a8a;
}

.section_title {
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 20px;
  margin: 0;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  /*noinspection CssUnknownProperty*/
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
