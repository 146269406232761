.root {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.canvas {
  width: 100%;
}

.zoom_div {
  position: absolute;
  right: -40px;
  top: 0;
  width: 40px;
  display: flex;
  flex-direction: column;
}

.zoom_div button {
  margin: 3px;
  width: 27px;
  height: 27px;
  padding: 5px !important;
}

@media only screen and (max-width: 550px) {
  .zoom_div {
    right: 0;
    visibility: hidden;
  }
}
