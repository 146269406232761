.root {
  text-align: center;
  display: flex;
  align-items: center;
  margin: 10px;
  font-weight: bold;
  color: black;
  text-decoration: none;
}

.root p {
  color: black;
  font-weight: bold;
}

.selected {
  color: var(--main-tint-color);
  text-decoration: underline;
}
