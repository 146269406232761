.root {
  height: 40px !important;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 3px;
  justify-content: center;
  color: white;
  display: flex;
  align-items: center;
  background-color: var(--main-tint-color);
  border: none;
}

.progress {
  cursor: not-allowed;
}

.loader {
  border: 3px solid white;
  border-top: 3px solid var(--main-tint-color);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
