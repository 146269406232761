.root {
  align-content: center;
}

.mobile_menu_items {
  padding-left: 20px;
}

.mobile_menu_items p {
  margin: 10px;
}

.top_part {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.right_menu_items {
  display: flex;
}

.root hr {
  border-color: var(--main-tint-color);
  background-color: var(--main-tint-color);
  color: var(--main-tint-color);
  border: none;
  height: 1px;
}

.expand_collapse_icon {
  margin: 20px;
}

@media only screen and (max-width: 800px) {
  .top_part {
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
  }
}
