.root {
  background: white;
  padding: 10px;
  width: fit-content;
  border-radius: 5px;
  box-shadow: 5px 5px 10px grey;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.coordinate {
  margin-bottom: 5px;
}

.close_button_div {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
