.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.billboard {
  max-width: 1000px;
  width: 90%;
}

.billboard_text {
  max-width: 1000px;
  width: 90%;
  font-size: 1.2em;
  text-align: center;
}

@media only screen and (min-width: 970px) {
  .billboard {
    width: 90vh;
    height: 90vh;
  }
}

.youtube_div_div {
  max-width: 800px;
  width: 90%;
}

.youtube_div {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* The height of the item will now be 56.25% of the width. */
}

/* Adjust the iframe so it's rendered in the outer-width and outer-height of it's parent */
.youtube_div iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
