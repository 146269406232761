.root {
  background: white;
  padding: 10px;
  width: fit-content;
  border-color: lightgrey;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  margin: 20px 0 10px 0;
}

.close_button_div {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.span_selected {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 1.15em;
}
